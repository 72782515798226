// Scss Document
@charset "UTF-8";

// 共通部分
// 全体の背景色
main {
	background-color: #fafafa;
}
// 文字をゴシックに
.pb450 {
	font-weight: 450;
}
// 黄色い下線
.yellow-line {
	background:linear-gradient(transparent 70%, #f7f04e 0%);
} 
// 白抜きピンク背景
.bg-pink {
	background-color: $third_color !important;
	color: #fafafa;
}
// 白抜きグリーン背景
.bg-green {
	background-color: $fourth_color !important;
	color: #fafafa;
}
// 白抜きブルー背景
.bg-blue {
	background-color: $first_color !important;
	color: #fafafa;
}



// トップ画像(浅草の街並み)部分
.swiper-container {
	position: relative;
	z-index: 1;
}
.top-bee-wrapper {
	display: none;
}
.top-bee {
	display: none;
}
.machinami_PC, .machinami_tab {
	display: none;
}
@media (min-width: 520px) {
	.machinami_mobile {
		display: none;
	}
	.machinami_tab {
		display: block;
	}
}
@media (min-width: 700px) {
	.machinami_tab {
		display: none;
	}
	.machinami_PC {
		display: block;
	}
	.top-bee-wrapper {
	display: block;
	position: absolute;
	top: 2%;
	left: 5%;
	z-index: 3;
	width: 90px !important;
    }
	.top-bee {
	display: block;
	object-fit: contain !important;
    width: 100%;
	height: auto;
}
}
@media (min-width: 980px) {
	.top-bee-wrapper {
	width: 100px !important;
    }
}
@media (min-width: 1350px) {
	.top-bee-wrapper {
	width: 120px !important;
    }
}

// スマホのみ　トップ下の情報
.section-info_wrapper {
	width: 88%;
	margin: 40px auto 0;
	padding: 32px 30px 0;
	border: solid 2px #6f6f6f;
	background-color: #d9f0f7;
}
.info {
	max-width: 230px;
	margin: 0 auto;
	padding: 0 20px 35px;
}
.info:last-child {
	padding-bottom: 40px;
}
.section-info_list2 {
	display: none;
}
@media (min-width: 600px) {
	.info-wrapper_bottom {
	display: flex;
	justify-content: space-around;
	}
}
@media (min-width: 858px) {
	.section-info_list {
		display: none;
	}
	.section-info_list2 {
		display: block;
	}
	.section-info_list2 {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	}
}
@include media(lg) {
	.section-info_mobile {
		display: none;
	}
}

// サブキャッチコピー
.sub-catchcopy-wrapper {
	margin-top: 36px;
}
.sub-catchcopy1, .sub-catchcopy2 {
	color: #333;
	text-align: center;
}
.sub-catchcopy1 {
	font-size: 1.4em;
	line-height: 1.9em;
}
.sub-catchcopy2 {
	font-size: 1.3em;
	margin-top: 13px;
}
@media (min-width: 700px) {
	.sub-catchcopy_br {
		display: none;
	}	
	.sub-catchcopy1 {
	font-size: 1.5em;
	}
　　 .sub-catchcopy2 {
	font-size: 1.4em;
	}
}
@include media(lg) {
	.sub-catchcopy1 {
	font-size: 1.4em;
	}
    .sub-catchcopy2 {
	font-size: 1.3em;
	}
}
@media (min-width: 1200px) {
	.sub-catchcopy1 {
	font-size: 1.5em;
	}
　　 .sub-catchcopy2 {
	font-size: 1.4em;
	}
}

// コロナの案内
.section-corona {
	padding:  0 0 40px;
	max-width: 980px;
	margin: 0 auto;
}
.section-corona-wrapper {
	width: 88%;
	margin: 40px auto 0;
	border: 2px solid #b0120a;
	padding: 10px 16px;
}
.section-corona__border-top {
	font-size: 1.1em;
	line-height: 27px;
	font-weight: 450;
	color: #b0120a;
	padding: 5px 0 10px;
}
.section-corona__ul li p{
	color: #333;
	font-size: 1.6rem;
	font-weight: 450;
	padding-bottom: 1px;
}
.corona-btn {
	margin: 12px 0;
	background-color: #bd1911 !important;
	p {
		max-width: 720px;
		margin: 0 auto;
		font-size: 1.6rem;
		font-weight: 600;
		color: #fafafa;
		display: flex;
		flex-direction: column;
	}
}
.corona-btn:hover {
	background-color: darken(#bd1911, 8%) !important;
}
.corona-btn_kuwashiku {
	font-size: 1.4rem;
}
.section-corona__kuwashiku {
	color: #fafafa;
}
@media (min-width: 420px) {
	.corona-br, .corona-br1 {
	display: none;	
}
}
@media (min-width: 460px) {
	.section-corona-wrapper {
	padding: 10px 40px;
}
	.section-corona__border-top {
	padding: 10px 0 12px;
}
}
@media (min-width: 700px) {
	.corona-btn p {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}

// お知らせ
.topics-wrapper {
	padding:  0 0 70px;
	width: 88%; 
	max-width: 780px;
	margin: 0 auto;
}
.topics-title {
	border-bottom: solid 2px #44af35;
    padding: .4em 0;
    margin: 0 auto 25px;
    font-size: 1.9em;
	font-weight: 600;
	color: #44af35;
	text-align-last: center;
}
.news-list li {
	font-size: 1.6rem;
	padding-left: 18px;
	dt {
		color: #44af35;
	}
	dd {
		line-height: 1.6 !important;
	}
}
.news-list li:first-child {
	padding-bottom: 26px;
}


// 浅草病院との連携
.section-alignment {
	background: linear-gradient(180deg, #a2daf5 0%, #a2daf5 50%, #c8e8f2 50%, #c8e8f2 100%);
	padding: 40px 20px;
}
.section-alignment__wrapper {
	max-width: 860px;
	background-color: #fafafa;
	width: 93%;
	margin: 0 auto;
	padding: 32px 15px 30px;
	position: relative;
}
.section-alignment__h {
	font-size: 1.4em;
	padding-bottom: 25px;
	letter-spacing: 0.02em;
}
.section-alignment__dsc {
	word-wrap:break-word;
	font-size: 1.6rem;
	padding-bottom: 20px;
}
@include media(md) {
	.section-alignment__wrapper {
	padding: 32px 38px 30px;
}	
}
@media (min-width: 840px) {
	.section-alignment__h {
		font-size: 1.5em;
	}
	.section-alignment__dsc {
	padding-right: 20px;
}
}
@media (min-width: 1300px) {
	.section-alignment__h {
		text-align: center;
	}
}
.bee-alignment {
	position: absolute;
	top: -33px;
	right: -20px;
	display: block;
	width: 70px !important;
}
@include media(lg) {
	.bee-alignment {
	position: absolute;
	right: -30px;
	width: 80px !important;
}
}

// サイドナビゲーションの診療時間・休診
.side-navigation-logo {
	padding-top: 26px;
}
.side-navigation-time_img {
	display: block;
}
.aaa {
	display: none;
	width: 90%;
	padding: 16px 0 34px 29px;
}
.bbb {
	display: none;
	width: 80%;
	padding: 10px 0 40px 29px;
}
@include media(lg) {
	.aaa, .bbb {
		display: block;
	}
}

// バナー
.banner-erea_wrapper {
	max-width: 719px;
	margin: 25px auto 10px;
}
.banner-erea_list {
    flex-wrap: wrap;
    display: flex;
	justify-content: space-around;
    align-items: top;
}
.banner {
	width: 220px !important;
	height: 43px;
	margin-bottom: 25px;
}
.banner-erea_list .banner {
    width: 32%;
    padding: 16px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
}
.banner-erea_list:after {
    content: "";
    display: block;
	width: 220px;
    height: 0;
}
.banner-erea_list:before {
    content: "";
    display: block;
	width: 220px;
    height: 0;
    order: 1;
}
