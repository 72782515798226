@charset 'UTF-8';

// burger-wrapper
.burger-wrapper {
  /* background: darken( $base-color, 20% ); */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: map-get( $header_height_array, sm );
  height: map-get( $header_height_array, sm );
  @include media(md) {
    width: map-get( $header_height_array, md );
    height: map-get( $header_height_array, md );
  }
  @include media(lg) {
    display: none;
  }
}



// burger
//
.burger-icon {
  display: block;
  width: map-get( $burger_border_width_array, 'sm' );
  span {
    /* background: $burger_border_color; */
	background-color: #023990;
    display: block;
    width: 100%;
    height: $burger_border_thickness; // 太さ
    transition: transform $transition, opacity $transition;
    &:nth-of-type(2) {
      margin: map-get( $burger_border_margin_array, 'sm' ) 0; // ボーダー同士の間隔
    }
  }
  @include media(md) {
    width: map-get( $burger_border_width_array, 'md' );
    span:nth-of-type(2) {
      margin: map-get( $burger_border_margin_array, 'md' ) 0; // ボーダー同士の間隔
    }
  }
  @include media(lg) {
    display: none;
  }
  // OPEN
  #{$burger_open_class} & {
    background: transparent;
    span {
      &:nth-of-type(1) {
        transform: translateY(6px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-6px) rotate(45deg);
      }
    }
    @include media(md) {
      span {
        &:nth-of-type(1) {
          transform: translateY(8px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-8px) rotate(45deg);
        }
      }
    }
    @include media(lg) {
      span {
        &:nth-of-type(1) {
          transform: translateY(12px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(12px) rotate(45deg);
        }
      }
    }
  }
}
