@charset 'UTF-8';

// wrapper
// -------
.global-navigation {
  position: relative;
}

// valiables
// @use _page.scss

// side navigation width
$side_navigation_width: 29rem;

// transition delay time
$menu_transition_time: .3s;

// navigation
// ----------
nav.side-navigation-wrapper {
  background: #d9f0f7;
  backface-visibility: hidden;
  border-right: solid 1px #a2daf5;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: $side_navigation_width;
  transform: translateX( - $side_navigation_width );
  transition: transform $menu_transition_time;
  z-index: 5;

  // メニューオープン
  #{$burger_open_class} & {
    transform: translateX(0);
  }

  @include media(lg){
    transform: translateX(0);
  }
}

.side-navigation li:hover {
		background: darken( #d9f0f7, 8%);
	.side-navigation-child {
		background: #d9f0f7;
	}
}

.side-navigation-child li:hover {
		background: darken( #d9f0f7, 6%);
	}




// parent ul
.side-navigation {
  margin: 0;

  & > li {
    /* border-bottom: 1px solid $gray-300;　*/
	   border-bottom: 1px solid #a2daf5;

    & > a {
      color: #333;
      display: block;
      padding: 1.2em 1em;
      position: relative;
      &:before {
        content: '';
		border-left: 6px solid #6bc5f0;
        margin-right: 1em;
		border-radius: 3px;
      }
    }
    &.is-child-menu:first-child  {
      & > a {
        &:after {
          font-family: $icon-font-family;
          content: '\f0d7';
          color: $gray-600;
          font-weight: 900;
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 1em;
          transform: translateY(-50%);
        }
      }
    }
  }
}

// child menu
.side-navigation-child {
  display: none;
  border-bottom: none;

  & > li {
    border-top: 1px solid $gray-300;
    position: relative;
    &:before {
      font-family: $icon-font-family;
      content: map-get( $icon_type_array, 'default' );
      display: inline-block;
      font-weight: 900;
      color: darken( $base-color, 15%);
      position: absolute;
      left: 1.5em;
      top: 50%;
      transform: translateY(-50%);
      @include media(md) {

      }
    }

    & > a {
      color: #111;
      display: block;
      font-size: 85%;
      padding: 1.2em 1em 1.2em 3.5em;
      background: darken(rgba($base-color, 0.1), 5%);
    }
  }
}

// メニュー上部のロゴ表示
.side-navigation-logo {
  display: none;
  padding: 2.5em 1em;

  & > a {
    padding: 0;

    & > img {
      /* width: 100%; */
		width: 90%;
		display: block;
		margin: 0 auto;
    }
  }

  @include media(lg) {
    display: block;
  }
}

// nav 内のバナーエリア
.banner-area {
  width: 200px;
  margin: 2em auto;

  li {
    margin-bottom: 1em;
    text-align: center;
  }
}


// メニューオープン時に body に付く class
#{$burger_open_class} {
  position: relative;
  overflow: hidden;
}
