// Scss Document
@charset "UTF-8";


// 下層ページ共通
.sub-container-wrapper {
	width: 95%;
	margin: 0 auto;
	max-width: 1100px;
}
.bee-icon {
	width: 30px;
}
.h-label {
	padding: 0px 6px;
	color: #fafafa;
	letter-spacing: 0.1em;
	display: inline-block;
	background-color: #6bc5f0;
	font-weight: 500;
}

// 下層ページ共通のトップ画像
.sub-visual {
	img {
		object-fit: contain;
	}
}

// 各ページのタイトルのはち
.title-bee-icon {
	vertical-align: bottom;
	width: 52px;
	padding-left: 10px;
	padding-bottom: 2px;
}

// 項目内の見出し
.sub-h4 {
	font-size: 1.2em;
	font-weight: 450;
	border-bottom: solid 1px $base_color; 
	padding-bottom: 6px;
	margin-bottom: 13px;
	color: $first_color;
}



// 「当院について」ページのscss
.sub-list {
	margin: 18px auto 0;
	div {
		padding: 0 14px 14px 0;
		height: 140px;
		width: 100%;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
}
.needs-float {
	height: 140px;
	width: 100%;
	img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
}
@media (min-width: 500px) {
	.sub-list div, .needs-float  {
		height: 200px;
	}
}
.shinryoka-list {
	display: flex;
	padding-bottom: 15px;
}
.ten {
	display: none;	
	}
@media (min-width: 760px) {
	.shinryoka-list {
	display: block;
	}
	.ten {
	display: inline-block;	
	}
}
.hotline-image {
	padding-top: 40px;
	img {
		display: block;
		max-width: 1025px;
		margin: 0 auto;
	}
}
.asakusa-link-btn {
	background: #2c598e !important;
	margin-top: 45px;
	width: 44% !important;
}
.asakusa-link-btn:hover {
	background: darken(#2c598e, 12%) !important;
}
.width44 {
	width: 44% !important;
}
@include media(md) {
	.width44 {
	width: 93% !important;
	}
	.asakusa-link-btn {
		width: 29% !important;
	}
}
.shinsatu-hyo {
	text-align: center;
	tr {
		th, td {
			vertical-align: middle;
		}
	}
}
@media (max-width: 489px) {
	.shinsatu-hyo_wrapper {
		width:100%;
  		overflow-x:scroll;
	}
	.shinsatu-hyo_table {
		border-collapse: collapse;
  		width: 500px;
	}	
}



//「院長のごあいさつ」 css
.intyo-pic, .intyo-name {
	display: none;
}
.intyo-name2 {
	display: block;
	margin-top: 5px !important;
	text-align: center;
}
.intyo-pic-bottom {
	max-width: 180px;
	padding-top: 25px;
	margin-left: auto;
}
@include media(md) {
	.intyo-pic-bottom, .intyo-name2 {
	display: none;
	}
	.intyo-pic {
	display: block;
	}
	.intyo-name {
		display: block;
		margin-top: 5px !important;
		text-align: center;
	}
}

// 「キャラクターについて」 css
.wide97 {
	width: 97% !important;	
}
.character-marginbottom {
	margin-bottom: 50px !important;
}
.character-main {
	max-width: 300px;
}
.character-main_p, .character_p {
	text-align: center;
}
@include media(md) {
	.character-main {
	max-width: 500px;
	padding-left: 10px;
		img {
			min-width: 290px;
		}
	}
	.character-main_p {
	text-align: left;
	padding: 30px 0 0 40px;
	}
	.character_p {
		br {
			display: none;
		}
	}
}
.character_list_frame_first {
	margin-top: 70px !important;
}
.character_list_frame_second {
	display: none;
}
.character-list {
	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content:space-around;
		li {
			padding: 18px 0;
			margin: 0 auto;
			text-align: center;
			img, p {
				margin-top: 5px !important;
			}
		}
		li:first-child {
			position: relative;
		}
	}
}
.character_p_second {
	white-space: nowrap;
	position: absolute;
	top: 310px;
	left: 7%;
}
@media (min-width: 758px) {
	.character-list ul li {
		width: 50%;
	}
}
@media (min-width: 1380px) {
	.character_list_frame_first  {
		display: none;
	}
	.character_list_frame_second {
		display: block;
	}
	.character-list ul li {
		width: 25%;
	}
}
.hanigura {
	font-size: 1.2em;
}

// 「フロアマップ」のページ css
.floormap-illast_map {
	padding-bottom: 45px;
}
.floormap-illast_wide {
	display: none;
}
.floormap-img {
	width: 100%;
	object-fit: cover;
	padding: 25px 22px 2px;
}
.floormap-img_p {
	text-align: center;
}
.floormap-illast_wrapper {
	padding-bottom: 60px;
}
.basyo-bg {
	display: block;
	color: #fafafa;
	font-weight: 500;
	font-size: 1.1em;
	margin: 4px 22px 0;
	background-color: $first_color;
}
@media (min-width: 550px) {
	.floormap-illast_wrapper {
		display: none;
	}
	.floormap-illast_wide {
	display: block;
	padding: 0 50px 40px;
	}
}



// 「診療内容」ページ css
.content-list {
	margin-bottom: 13px;
	li {
	margin-bottom: 10px;
	font-weight: 450;
	}
}
.zyunkanki-dsc {
	display: inline-block;
	font-weight: 450;
	padding-bottom: 8px;
}
.p-margintop {
	margin-top: 2px;
	padding-bottom: 22px;
}



// 「訪問診療」ページ css
.hyouback-wrapper {
	background-color: $five_color;
	border: solid 1px #9ad7ef;
	padding: 16px 13px;
	p {
		font-size: 16px;
		font-weight: 500;
		padding-bottom: 15px;
	}
	ul {
		li {
			padding-bottom: 7px;
		}
	}
}
.step-wrapper {
	max-width: 650px;
	margin: 0 auto;
}
.step-box {
	display: block;
	width: 100%;
	margin: 0 auto 68px;
	padding: 20px 4px;
	background-color: $five_color;
	border: solid 1px #9ad7ef;
	position: relative;
	p {
		display: flex;
		width: 100%;
		margin: 0 auto;
	}
}
.step-box::after {
   content: '';
   position: absolute;
   bottom: -50px;
   left: 45%;
   width: 0;
   height: 0;
   border: none;
   border-top: 30px solid $base_color;
   border-right: 35px solid transparent;
   border-left: 35px solid transparent;
   // content: none;
}
.step-number, .step-p {
	display: inline-block;
	font-weight: 450;
}
.step-number {
	width: 33%;
	text-align: center;
	color: #fafafa;
	font-weight: 600;
}
.step-number_bg {
	display: inline-block;
	background-color: $first_color;
	padding: 5px 8px;
}
.step-p {
	width: 76%;
	text-align: center;
	position: relative;
}
.step-p_abso {
	position: absolute;
	top: 4px;
	left: 0;
  	right: 0;
  	margin: auto;

}
.step-box:last-child::after {
	display: none;
}
.step-box:last-child {
	margin-bottom: 20px;
}
.home-addresscolor {
	color: #023990;
}
@media (max-width:560px) {
   .step-box::after {
   left: 42%;
   }
}
.hospitalname-home {
	position: relative;
}
.bee-home {
	position: absolute;
	right: 30px;
	top: -20px;
	display: block;
	width: 70px !important;
}
@include media(lg) {
	.bee-home {
	width: 90px !important;
}
}



// 「健康診断」ページ css
.checkup-lead {
	padding-bottom: 30px;
}
.option-hyo tr td {
	text-align: center;
}
.line-height_table {
	line-height: 1.9em;
}
.menu-name {
	padding-right: 14px;
}
.price {
	padding-left: 14px;
}
.benbaiyo {
	text-align: left !important;
}
@media (min-width: 470px) {
	.benbaiyo {
	text-align: center !important;
	}
}



// 「予防接種」ページ css
.vaccination-hyo {
	tr {
		th, td {
		text-align: center;
		}
	}
}



// 「アクセス」 css
.wrapper-md {
	width: 97%;
	margin: 0px auto;
}
.access-train-wrapper {
	ul{
		li {
			padding-bottom: 22px;
			.access-h {
				padding: 0px 6px;
				color: #fafafa;
				letter-spacing: 0.1em;
				display: inline-block;
				background-color: #6bc5f0;
				font-weight: 500;
			}
				p {
					margin-top: 2px;
				}
			}
		li:last-child {
			padding-bottom: 0;
		}
	}
}
.kouiki {
	margin: 0 auto 8px;
}
.mar-bot {
	margin-bottom: 45px !important;
}
.googlemap-frame, .chizu-frame {
	width: 92%;
	margin: 0 auto;
	height: 80vw;
	max-height: 500px;
	overflow: hidden;
	padding-bottom: 56.25%;
	margin-bottom: 50px;
	position: relative;
}
.googlemap, .chizu {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}



// はじめての方へ css
.first-float-space {
	margin-bottom: 25px;
}
.pic-float {
	height: 140px;
	width: 100%;
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
.first-float {
	padding-left: 17px;
}
@media (min-width: 500px) {
	.pic-float {
		height: 180px;
	}
}
@media (min-width: 700px) {
	.pic-float {
		height: 210px;
	}
}
@media (min-width: 1050px) {
	.pic-float {
		height: 250px;
		max-width: 390px; 
	}
}
.hajimete-th, .hajimete-td, .kanzyaonegai-th, .kanzyaonegai-td {
	display: block;
	padding: 15px;
	line-height: 25px;
	font-size: 1.1em;
}

.hajimete-th, .kanzyaonegai-th {
	color: #fafafa;
	font-weight: 500;
	text-align: left;
	background: $first_color !important;
}
.hajimete-th {
	margin-top: 20px;
}
.kanzyaonegai-td {
	margin-bottom: 30px;
}
.asakusa-kensa {
	padding: 15px;
	font-weight: 450;
}



// 「お問い合わせ」ページ css
.mail-here {
	width: 100% !important;
	margin: 0 auto;
}
.info-address-wrapper {
	margin-bottom: 40px;
	p {
		margin-top: 0;
	}
}
.mail-address {
	padding-top: 8px;
	a {
		color: #023990;
	}
}
.p-dsc_mail {
	padding-top: 14px;
}



// 「よくある質問」ページ css
.qa-lead {
	text-align: center;
	font-weight: 500px;
	font-size: 1.1em;
	padding-bottom: 30px;
}
.qa-para {
	margin-bottom: 40px;
}
.qa-th {
	text-align: left;
}
@media screen and (min-width: 47.5em) {
	.qa-th {
	text-align: center;
}
}



//　「採用情報」ページ css
.recruit-button-list {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	li {
		margin: 0 0 25px 25px;
	}
}
.width200 {
	width: 200px !important;
}
.p-paddingbottom {
	padding-bottom: 20px;
}



// 「コロナ感染対策強化について」ページ css
.covid19-list li {
	padding-bottom: 20px;
}