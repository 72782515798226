@charset 'UTF-8';

// button
//
.button {
  background: multi-array( $button_color_array, 'base', 'bg' );
  border: $button_border solid transparent;
  border-radius: $button_border_radius;
  color: multi-array( $button_color_array, 'base', 'color' );
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  line-height: 1.42875 !important;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: background $transition, color $transition;
  vertical-align: middle;
  padding: calc( #{map-get( $button_padding_vertical_array, sm )} + 1px ) map-get( $button_padding_horizontal_array, sm ) map-get( $button_padding_vertical_array, sm );
  @include media(md) {
    padding: calc( #{map-get( $button_padding_vertical_array, md )} + 1px ) map-get( $button_padding_horizontal_array, md ) map-get( $button_padding_vertical_array, md );
  }
  @include media(lg) {
    padding: map-get( $button_padding_vertical_array, lg ) map-get( $button_padding_horizontal_array, lg ) map-get( $button_padding_vertical_array, lg );
  }
  // Hover
  &:hover {
    background: multi-array( $button_hover_color_array, 'base', 'bg' );
    color: multi-array( $button_hover_color_array, 'base', 'color' );
    text-decoration: none;
  }
  // Color Pattern
  @each $name, $value in $button_color_array {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      border: $button_border solid transparent;
      color: map-get( $value, 'color' );
      &:hover {
        background: multi-array( $button_hover_color_array, #{$name}, 'bg' );
        color: multi-array( $button_hover_color_array, #{$name}, 'color' );
      }
    }
  }
  // 文字サイズによる padding-top & padding-bottom の上書き
  //
  @each $list in $font_size_key_array {
    &.#{$list} {
      padding: map-get( $button_size_padding_vertical_array, $list ) map-get( $button_size_padding_horizontal_array, $list );
    }
  }
} // .button



// button outline
//

.button.outline {
  background: multi-array( $button_outline_color_array, 'base', 'bg' );
  border: $button_border solid multi-array( $button_outline_color_array, 'base', 'border' );
  color: multi-array( $button_outline_color_array, 'base', 'color' );
  text-shadow: none;
  // Hover
  &:hover {
    background: multi-array( $button_outline_hover_color_array, 'base', 'bg' );
    color: multi-array( $button_outline_hover_color_array, 'base', 'color' );
    text-decoration: none;
  }
  // Color Pattern
  @each $name, $value in $button_outline_color_array {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      border: $button_border solid map-get( $value, 'border' );
      color: map-get( $value, 'color' );
      &:hover {
        background: multi-array( $button_outline_hover_color_array, #{$name}, 'bg' );
        color: multi-array( $button_outline_hover_color_array, #{$name}, 'color' );
      }
    }
  }
}



// expanded
//
.button.expanded {
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  background: $fourth_color;
  color: #fafafa;
  font-weight: 600;
}
.button.expanded:hover {
	background: darken($fourth_color, 15%);
}



// button icon
//
.button,
.button.outline {
  span {
    &::after {
      @include icon_default;
      margin-left: .6em;
    }
    &.left {
      &::after { content: none; }
      &::before {
        @include icon_default;
        margin-right: .6em;
      }
    }
    // icon-type
    @each $name, $icon in $icon_type_array {
      &.#{$name} {
        &::after {
          content: $icon !important;
        }
        &.left  {
          &::after {
            content: none !important;
          }
          &::before {
            content: $icon !important;
          }
        }
      }
    }
    // icon color
    @each $name, $color in $color_pattern_array {
      &.ic-#{$name} {
        &::after, &::before {
          color: $color;
        }
      }
    }
  } // span
}
