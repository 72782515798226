@charset 'UTF-8';

// SELECT PLAN
// Template 03



// 全体のラッパー
.base-contents-wrapper {
  position: relative;
  width: 100%;
}



// メインコンテンツ
.main-contents-wrapper {
  backface-visibility: hidden;
  //transform: translateX(0);
  -webkit-overflow-scrolling: touch;
  transition: transform $menu_transition_time;

  @include media(lg){
    margin-left: $side_navigation_width;
  }

  // メニューオープン時にコンテンツを動かす
  #{$burger_open_class} & {
    transform: translateX( $side_navigation_width );
  }
}



// TOP PAGE
// --------

// Slider
.slider-wrapper {
  margin-bottom: 3em;
  width: 100%;
  img {
	width: 100%;
  	object-fit: cover;
	object-position: 42% 0%;
  }
}

// トピックス
.top-topics {
  a {
    img {
      transition: box-shadow .3s;
    }
    &:hover img {
      box-shadow: 0 0 9px 2px #ccc;
    }
  }
}

// 新着情報
.top-news-list {
  font-size: 1.125em;
  margin-bottom: 2rem;
  li {
    padding: 1rem 0;
    border-bottom: 1px dotted $fourth-color;
    @include media(lg) {
      padding: 1.6rem 0;
    }
    &:first-child{
      padding-top: 0;
    }
    dl {
      line-height: 1.6;
      dt {
        display: block;
        color: $second-color;
      }
    }
  }
}