@charset 'UTF-8';

// $font_size_array 配列から key を取り出してリスト化
//
$font_size_key_array: map-keys( $font_size_array );



// 文字サイズによる padding
//
// リストの順番 'exlarge', 'xxlarge', 'xlarge', 'large', 'medium', 'small', 'xsmall'
$button_size_padding_vertical_value_array:   ( .5em, .5em, .6em, .6em, .7em, .7em, .7em );
$button_size_padding_horizontal_value_array: ( .5em, .5em, 1.25em, 1.25em, 1.25em, 1.25em, .7em );

// map-zip( $keys, $values ) キーのリスト、値のリストの二つから新しい map を生成して返す
//
$button_size_padding_vertical_array:   map_zip( $font_size_key_array, $button_size_padding_vertical_value_array );
$button_size_padding_horizontal_array: map_zip( $font_size_key_array, $button_size_padding_horizontal_value_array );



// button 上下の padding
//
$button_padding_vertical_array: (
  sm: .7em,
  md: .7em,
  lg: .7em
) !default;



// button 左右の padding
//
$button_padding_horizontal_array: (
  sm: 1em,
  md: 1em,
  lg: 1.25em
) !default;



// 角丸
//
$button_border_radius: 1px !default;



// アウトライン時のボーダーの太さ
$button_border: 1px !default;



// button color
//

// background-color, color
$button_color_array: (
  'base'   : ( 'bg' : $base-color,   'color' : $white-color ),
  'first'  : ( 'bg' : $first-color,  'color' : $white-color ),
  'second' : ( 'bg' : $second-color, 'color' : $white-color ),
  'third'  : ( 'bg' : $third-color,  'color' : $white-color ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : $white-color ),
  'five'   : ( 'bg' : $five-color,   'color' : $white-color )
) !default;

$button_hover_color_array: (
  'base'   : ( 'bg' : darken($base-color, 10%),   'color' : $white-color ),
  'first'  : ( 'bg' : darken($first-color, 10%),  'color' : $white-color ),
  'second' : ( 'bg' : darken($second-color, 10%), 'color' : $white-color ),
  'third'  : ( 'bg' : darken($third-color, 10%),  'color' : $white-color ),
  'fourth' : ( 'bg' : darken($fourth-color, 10%), 'color' : $white-color ),
  'five'   : ( 'bg' : darken($five-color, 10%),   'color' : $white-color )
) !default;



// outline
//

// background-color, color, border-color
$button_outline_color_array: (
  'base'   : ( 'bg' : $white-color, 'color' : $base-color,   'border' : $base-color ),
  'first'  : ( 'bg' : $white-color, 'color' : $first-color,  'border' : $first-color ),
  'second' : ( 'bg' : $white-color, 'color' : $second-color, 'border' : $second-color ),
  'third'  : ( 'bg' : $white-color, 'color' : $third-color,  'border' : $third-color ),
  'fourth' : ( 'bg' : $white-color, 'color' : $fourth-color, 'border' : $fourth-color ),
  'five'   : ( 'bg' : $white-color, 'color' : $five-color,   'border' : $five-color )
) !default;

$button_outline_hover_color_array: (
  'base'   : ( 'bg' : $base-color,   'color' : $white-color, 'border' : $base-color ),
  'first'  : ( 'bg' : $first-color,  'color' : $white-color, 'border' : $first-color ),
  'second' : ( 'bg' : $second-color, 'color' : $white-color, 'border' : $second-color ),
  'third'  : ( 'bg' : $third-color,  'color' : $white-color, 'border' : $third-color ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : $white-color, 'border' : $fourth-color ),
  'five'   : ( 'bg' : $five-color,   'color' : $white-color, 'border' : $five-color )
) !default;
